import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-introduction.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { Body, Title, Disclosure } from 'legos/typography';
import { createElement, Fragment } from 'react';
import { EnrollmentContainer } from './styles/acp-biometrics-intoduction-success-failure.ccm.css';
import { ButtonRaised } from 'legos/button/raised';
import { ButtonFlat } from 'legos/button/flat';
import { ContentBlock } from 'legos/content-block';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { CenterChildren } from '../components';
import { SystemIcon } from 'legos/system-icon';
import { Illustration } from 'legos/illustration';
import { isAndroid, isIOS } from 'packages/platform-detector';
import { SendEventAnalytics } from 'apps/acp/packages/react-analytics';
import { BIOMETRICS_ANALYTICS_CONSTANTS } from '../acp-biometrics-ga-analytics';
import { useSendAnalytics } from 'packages/react-analytics';
import { useHistory } from 'packages/react-nano-router';
export const BiometricsIntroduction = ({
  title,
  biometricsType,
  brand,
  backLinks,
  nextGoLink
}) => {
  const sendAnalytics = useSendAnalytics();
  const history = useHistory();
  const moveBack = {
    /**
     * This will help in capture send analytics event
     * when clicked on InPageFlowLayout back button.
     */
    href: '#',
    onClick: e => {
      e.preventDefault();
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.DISMISS,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  };
  return createElement(Fragment, null, createElement(SendEventAnalytics, {
    analyticsEvent: {
      event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.WARNING.VIEWED,
      payload: {
        category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
        label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
      }
    }
  }), createElement(InPageFlowLayout, {
    title: title,
    backAnchor: moveBack
  }, createElement(ContentBlock, null, createElement(EnrollmentContainer.div, null, biometricsType === 'touch' && createElement(IntroIconFingerPrint, {
    type: biometricsType
  }), biometricsType === 'face' && createElement(IntroIconFace, null), createElement(Title, null, biometricsType === 'touch' && createElement(Content, {
    hash: "dc5b1a714f4efe0c27d9f4348356d95c",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "0449d2e9e852e1a51c7c57e561faf074",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, biometricsType === 'touch' && createElement(Content, {
    hash: "514a2927d29ece1a9deeb839bcabebd8",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), biometricsType === 'face' && createElement(Content, {
    hash: "df27da8fb6f2ba7a382d828100b8f3e7",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1655e5c4d99c7fcc914c7aab129a4853",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Fingerprint' : 'Face ID', () => biometricsType === 'touch' ? 'fingerprint access' : 'Face ID'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(Disclosure, null, createElement(Content, {
    hash: "37fd75e9777c841c4bf2ffc2c98212b0",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(CenterChildren, null, createElement(ButtonRaised, {
    color: "positive",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_GET_STARTED,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', nextGoLink.url());
    }
  }, createElement(Content, {
    hash: "1dac0878f52e89415333483d9597157e",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Get Started' : 'Close'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), biometricsType === 'touch' && createElement(ButtonFlat, {
    color: "default",
    onClick: () => {
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.BIOMETRICS_INTRODUCTION.WARNING_CANCEL,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  }, createElement(Content, {
    hash: "e67a4943fbde9735b8c39d3511cda639",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), biometricsType === 'face' && createElement(Disclosure, null, createElement(Content, {
    hash: "1ed81a4accce78191f52808172a22d27",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))))));
};

const IntroIconFingerPrint = ({
  type
}) => {
  return createElement(Fragment, null, type === 'touch' && isAndroid() && createElement(SystemIcon, {
    type: "fingerprintAndroid",
    color: "primary",
    size: 9
  }), type === 'touch' && isIOS() && createElement(SystemIcon, {
    type: "fingerprint",
    color: "primary",
    size: 9
  }));
};

const IntroIconFace = () => createElement(Illustration, {
  type: "face-id",
  label: "image illustrating face id"
});