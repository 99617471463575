import __NS_DYNAMIC_REACT_CONTENT___0 from "./biometrics-security-path-content.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
export const BiometricsSecurityPathDashboard = ({
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  return dashboardMfeEnabled ? createElement(Fragment, null, loginPreferencesMfeEnabled ? createElement(Content, {
    hash: "18466d4be407c11cd50de83459ede1a7",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "ee2bdd1d8e7cdbbf415b1f30d3cb34ba",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })) : createElement(Content, {
    hash: "a84f3ee38a06c425297b25ce7b008050",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};