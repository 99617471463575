import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-esign.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { ContentBlock } from 'legos/content-block';
import { Body, Bold, LinkBody, Title } from 'legos/typography';
import { EsignLayout, MrgTop, CommunicationLayout, LiLayout } from './styles/acp-biometrics-esign.ccm.css';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
import { useAcpBiometricsTheme } from '../app.theme';
export const BiometricsEsign = ({
  title,
  cancelAnchor,
  children
}) => {
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(Title, null, "E-Sign Consent"), createElement(CommunicationLayout.div, null, createElement(Body, null, "Communications will be sent to the following e-mail address we have on file for you. You can change your email address below."), children)));
};
export const EsignDisclosureContent = ({
  cardType,
  esignProductName,
  brand
}) => {
  const {
    lightDisclosureGreyColor,
    neutralBlackColor
  } = useAcpBiometricsTheme();
  return createElement(EsignLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(MrgTop.div, null, createElement(Body, null, createElement(Content, {
    hash: "1bff4315fd1396a1804ab3c9f34ce8cf",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Body, null, createElement(Content, {
    hash: "152447f4c6982d2f2e9a42f7c5f06178",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "be4dcbc453bc39bd0cad20865c9b460d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "f8fc0d830dc64fcdfc73ddf9de9e57f0",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "585baef2d577eb1bd5edb99e7b5304f5",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "614660c982059fd4b1536cb8657e47cf",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    })],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "b9601ea8bbd77c7efa6b6dbe33fdcc2e",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "13831c1c3e97aa56f201f05e286e1adb",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "0665fa542f6a95a5c22a54f5ef1a7e6e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "83e6dbb1787411c1e569e9c5bf9629ec",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "088c4a5734690f51dd8f25bec9761845",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "c53fd66941f31cb6cac7369b00f0ddaa",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "cd27eaad8fa21cab7cbf5858a4dcca8d",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "3122282c1524d7bb1641acfd6146c64f",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "3b2f4ce3f3dbe8712ae0bf91d374f23e",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "67fa0b56b780e6dee4a44d678b7fd279",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "a6b6659470e2381736d3c444171e4d12",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "ec1e73efafd8defc11383ef2d8e45d5f",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "d198957e5ec22af88fb63276d31052d2",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "b2d295cd4f55ab457f4d6912398a7a4d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "9a645a45e90f9f4ff79f095c838e95ab",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "c73b2a863eb0b823a36279f80e40fd7c",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(Bold, null, createElement(LinkBody, useExternalLinkHandler(`tel:${brand.phone}`), brand.phone_formatted)), ' ', createElement(Content, {
    hash: "746e77a5c517406d2234126aa7cc985a",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "d98d5852e704dfb04bd6922c77a05a07",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "3816e00a1a5545885cf667a1b0d544a4",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "74a120c6de62343f60770d0a745a20a9",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })));
};