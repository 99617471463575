function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-success.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { Body, Title } from 'legos/typography';
import { createElement, Fragment } from 'react';
import { ButtonRaised } from 'legos/button/raised';
import { ContentBlock } from 'legos/content-block';
import { EnrollmentContainer, HeadingContainer, ChangeOrDisable } from './styles/acp-biometrics-intoduction-success-failure.ccm.css';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { CenterChildren } from '../components';
import { Illustration } from 'legos/illustration';
import { BiometricsSecurityPathDashboard } from './biometrics-security-path-content';
import { SendEventAnalytics } from 'apps/acp/packages/react-analytics';
import { BIOMETRICS_ANALYTICS_CONSTANTS } from '../acp-biometrics-ga-analytics';
export const BiometricsSuccess = ({
  title,
  cancelAnchor,
  biometricsType,
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  const titleContent = biometricsType === 'touch' ? createElement(Content, {
    hash: "68d5bbf33b3a400fe2f4cf4289057a35",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "545aa3a05d0ef52758c98200a4467912",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
  return createElement(Fragment, null, createElement(SendEventAnalytics, {
    analyticsEvent: {
      event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.ENROLLMENT.CONFIRMATION,
      payload: {
        category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
        label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
      }
    }
  }), createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(EnrollmentContainer.div, null, createElement(Illustration, {
    type: "success",
    label: "image illustrating success check"
  }), createElement(HeadingContainer.div, null, createElement(Title, null, titleContent)), createElement(ChangeOrDisable.div, null, createElement(Body, null, createElement(Content, {
    hash: "2d017766d94d3d33dc4b3048cb653cc7",
    devVariables: {},
    componentsAndExpressions: [() => biometricsType === 'touch' ? 'Fingerprint' : 'Face ID'],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(BiometricsSecurityPathDashboard, {
    dashboardMfeEnabled: dashboardMfeEnabled,
    loginPreferencesMfeEnabled: loginPreferencesMfeEnabled
  }))), createElement(CenterChildren, null, createElement(ButtonRaised, _extends({
    color: "positive"
  }, cancelAnchor), createElement(Content, {
    hash: "92137d4e43f104e9d4c13d3be009ecdd",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))))));
};