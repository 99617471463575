export default { 
  h0449d2e9e852e1a51c7c57e561faf074: [[({
  brand: brand
}, []) => ["Enable Facial Recognition Login for Your ", brand.name, " Account."], {}]],
  h1655e5c4d99c7fcc914c7aab129a4853: [[({}, [C0, C1]) => ["Simply check the Enable", " ", C0(), " Login checkbox on the Login page and follow the instructions. If you haven\u2019t already, you will also need to enable", " ", C1(), " ", "in your device\u2019s settings."], {}]],
  h1dac0878f52e89415333483d9597157e: [[({}, [C0]) => [C0()], {}]],
  h1ed81a4accce78191f52808172a22d27: [[({}, []) => ["Face ID is a registered trademark of Apple. Inc."], {}]],
  h37fd75e9777c841c4bf2ffc2c98212b0: [[({}, []) => ["REMEMBER: Anyone with a stored fingerprint on this device will have access to your Online Account."], {}]],
  h514a2927d29ece1a9deeb839bcabebd8: [[({
  brand: brand
}, []) => ["You are about to enable Fingerprint Login for quick access to the ", brand.name, " mobile app as an alternative to your username and password."], {}]],
  hdc5b1a714f4efe0c27d9f4348356d95c: [[({
  brand: brand
}, []) => ["Your ", brand.name, " Account is a Fingerprint Away"], {}]],
  hdf27da8fb6f2ba7a382d828100b8f3e7: [[({
  brand: brand
}, []) => ["You now have the option of using Face ID\xAE to log in to the", " ", brand.name, " mobile app! Once you enable this option, login will be much easier and faster!"], {}]],
  he67a4943fbde9735b8c39d3511cda639: [[({}, []) => ["Cancel"], {}]] 
}